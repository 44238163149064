import styled, { css } from 'styled-components'

import { ErrorMessage, BannerPageWrapper } from '@sketch/components'

const ProcessingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
`

const PageContent = styled.div<{ $isRefreshedUi?: boolean }>`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  ${({ $isRefreshedUi }) =>
    $isRefreshedUi &&
    css`
      background-color: ${({ theme }) => theme.colors.background.secondary.B};
    `}
`

const ProcessingMessage = styled(ErrorMessage)`
  max-width: 480px;
`

const PageLoading = styled(PageContent)`
  justify-content: center;
  align-items: center;
`

const BannerWrapper = styled(BannerPageWrapper)`
  margin: 0 16px;
`

const NavbarBackButtonItem = styled.div`
  margin: 0 8px 0 0;
  line-height: 1;
`

export {
  ProcessingContainer,
  PageContent,
  ProcessingMessage,
  PageLoading,
  BannerWrapper,
  NavbarBackButtonItem,
}
