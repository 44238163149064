import React, { FC } from 'react'
import { Redirect, useLocation } from 'react-router'

import { routes, useFlag } from '@sketch/modules-common'

import { ErrorHandler } from '@sketch/tracing'
import { getCanAdministerShares } from 'modules/shares/utils'

import {
  DocumentHead,
  LoadingState,
  Pill,
  useModalContext,
} from '@sketch/components'
import DocumentItemsLayout from 'modules/shares/components/DocumentItemsLayout'
import { DocumentItemDropdown } from 'modules/shares/components/DocumentItemDropdown'
import { ShareTrashedModal } from 'modules/shares/components/ShareTrashedModal'
import CollectionDropdown from 'modules/collections/components/CollectionDropdown'
import CollectionItemsLayoutNew from 'modules/collections/components/CollectionItemsLayout'
import CollectionItemsLayoutOld from 'modules/collections/components/CollectionItemsLayoutOld'

import ProjectHeaderNew from '../ProjectHeader'
import ProjectHeaderOld from '../ProjectHeaderOld'

import ProjectEmptyState from '../ProjectEmptyState'
import ProjectCollectionError from '../ProjectCollectionError'
import {
  HeaderAfterCollections,
  DocumentsWrapper,
} from './ProjectShares.styles'

import { useSearch } from 'modules/shares/hooks/useSearch'
import { useSearchFilters } from 'modules/shares/hooks/useSearchFilters'
import { useProject } from 'modules/projects/hooks/useProject/useProject'

import {
  WorkspaceMinimalFragment,
  useGetDocumentListSettingsQuery,
  ShareListItemFragment,
} from '@sketch/gql-types'
import { useSortingSettings } from 'modules/shares/hooks/useSortSettings'
import { ScrollToTop } from 'components/ScrollToTopButton'

export interface ProjectSharesProps {
  projectId: string
  workspace: WorkspaceMinimalFragment
  userIsEditor: boolean
  userCanRenameProject: boolean
  userCanManageCollection: boolean
}

interface ProjectDocumentListProps {
  projectId: string
  workspace: WorkspaceMinimalFragment
  totalCount: number
  onLoadMore: () => Promise<any>
  shares: ShareListItemFragment[]
}

const ProjectDocumentList: FC<ProjectDocumentListProps> = ({
  workspace,
  projectId,
  totalCount,
  onLoadMore,
  shares,
}) => {
  const { showModal } = useModalContext()
  const { sort } = useSortingSettings()
  const queryVariables = {
    shortId: projectId,
    after: null,
    search: {
      name: null,
      isCurrentVersionDownloadable: null,
      filters: ['NO_COLLECTION'],
    },
    sortOrder: sort,
  }

  return (
    <DocumentItemsLayout
      projectId={projectId}
      shares={shares}
      showProjectName={false}
      showCollectionName
      totalCount={totalCount}
      onLoadMore={onLoadMore}
      renderDropdown={item => {
        // Rendering a Share Item
        const share = shares.find(share => share.identifier === item.identifier)

        if (!share) {
          ErrorHandler.shouldNeverHappen(
            'share should always be present rendering the ProjectDocumentDropdown'
          )
          return null
        }

        return (
          <DocumentItemDropdown
            share={share}
            workspaceIdentifier={workspace.identifier}
            queryVariables={queryVariables}
            workspaceStatus={workspace.status}
          />
        )
      }}
      action={item => {
        // This action is triggered when the user clicks the DocumentItem
        const isShare = item.__typename === 'Share'
        if (isShare && item.deletedAt) {
          return () => showModal(ShareTrashedModal, { share: item })
        }

        return 'link'
      }}
    />
  )
}

const ProjectShares: FC<ProjectSharesProps> = props => {
  const {
    workspace,
    projectId,
    userIsEditor,
    userCanRenameProject,
    userCanManageCollection,
  } = props
  const location = useLocation()
  const { searchDebounced: search } = useSearch()
  const { filters } = useSearchFilters()

  const isRefreshedUi = useFlag('ui-refresh-24')
  const ProjectHeader = isRefreshedUi ? ProjectHeaderNew : ProjectHeaderOld
  const CollectionItemsLayout = isRefreshedUi
    ? CollectionItemsLayoutNew
    : CollectionItemsLayoutOld

  const { identifier: workspaceId } = workspace

  const { data, loading, error, loadMore } = useProject({
    workspaceId,
    projectId,
    search,
    filters,
  })

  // Load the document layout
  const { data: documentListSettingsData } = useGetDocumentListSettingsQuery()
  const { documentsLayout } = documentListSettingsData || {}

  const isCurrentlyInProjectView =
    location.pathname ===
    routes.WORKSPACE_PROJECT.create({ workspaceId, projectId })

  const isCurrentlyInTrashProjectView =
    location.pathname ===
    routes.WORKSPACE_TRASH_PROJECT.create({ workspaceId, projectId })

  const canUserAdministerShares = getCanAdministerShares(workspace)

  if (loading) {
    return <LoadingState />
  }

  if (error) {
    return (
      <ProjectCollectionError
        projectId={projectId}
        error={error!}
        type="project"
      />
    )
  }

  const project = data.project
  const collections = data.collections?.entries ?? []
  const isDraftsProject = project?.type === 'PERSONAL_DRAFTS'

  const shares = project?.shares.entries ?? []
  const hasCollections = !!collections.length
  const hasShares = !!shares.length

  const totalCount = {
    shares: project?.shares.meta.totalCount ?? 0,
    collections: data.collections?.meta.totalCount ?? 0,
  }

  /* ==========
   * Handle redirects when trashing / restoring projects
   * ==========
   * > Redirect to the Trash Project View if the project has been soft-deleted */

  if (!!project?.deletedAt && isCurrentlyInProjectView) {
    return (
      <Redirect
        to={routes.WORKSPACE_TRASH_PROJECT.create({
          projectId,
          workspaceId,
        })}
        push={false}
      />
    )
  }

  /*
   * > Redirect to the Project View if the project was restored */
  if (!project?.deletedAt && isCurrentlyInTrashProjectView) {
    return (
      <Redirect
        to={routes.WORKSPACE_PROJECT.create({
          projectId,
          workspaceId,
        })}
        push={false}
      />
    )
  }
  /* ==========
   * ========== */

  // Project Empty State
  if (!loading && shares.length === 0 && collections.length === 0) {
    return (
      <ProjectEmptyState
        workspace={workspace}
        project={project!}
        search={search}
        userIsEditor={userIsEditor}
        userCanRenameProject={userCanRenameProject}
        canUserAdministerShares={canUserAdministerShares}
        userCanManageCollection={userCanManageCollection}
      />
    )
  }

  const renderDocumentsList = () => {
    if (!hasShares) {
      return null
    }

    if (hasCollections) {
      const isGridLayout = documentsLayout === 'GRID'

      return (
        <DocumentsWrapper
          listLayout={!isGridLayout}
          $isRefreshedUi={isRefreshedUi}
        >
          {/**
           * This header should only be seen when the user
           * has both shares and collections
           * and is on the grid view
           */}
          {isGridLayout && (
            <HeaderAfterCollections>
              Documents
              <Pill variant="secondary">{totalCount.shares}</Pill>
            </HeaderAfterCollections>
          )}
          <ProjectDocumentList
            projectId={projectId}
            workspace={workspace}
            shares={shares}
            totalCount={totalCount.shares}
            onLoadMore={loadMore.project}
          />
        </DocumentsWrapper>
      )
    }

    // return list without gradient wrapper when there's no collections
    return (
      <ProjectDocumentList
        projectId={projectId}
        workspace={workspace}
        shares={shares}
        totalCount={totalCount.shares}
        onLoadMore={loadMore.project}
      />
    )
  }

  return (
    <>
      <DocumentHead title={isDraftsProject ? 'Drafts' : project?.name} />
      {project && (
        <ProjectHeader
          project={project}
          workspace={workspace}
          workspaceAccessLevel={project?.workspaceAccessLevel!}
          userCanRenameAndDeleteProject={userCanRenameProject}
          userCanManageCollection={userCanManageCollection}
        />
      )}
      {hasCollections && (
        <>
          <CollectionItemsLayout
            workspaceId={workspaceId}
            projectId={projectId}
            collections={collections}
            onLoadMore={loadMore.collections}
            showProjectName={false}
            totalCount={totalCount.collections}
            renderDropdown={item => {
              // Rendering a Collection Item
              const userCanManageCollection =
                userIsEditor || canUserAdministerShares

              const collection = collections.find(
                (collection: any) => collection.identifier === item.identifier
              )

              if (!collection) {
                ErrorHandler.shouldNeverHappen(
                  'share should always be present rendering the ProjectDocumentDropdown'
                )
                return null
              }

              return (
                <CollectionDropdown
                  userCanManageCollection={userCanManageCollection}
                  workspace={workspace}
                  collection={collection}
                  project={project!}
                />
              )
            }}
          />
          <ScrollToTop />
        </>
      )}

      {renderDocumentsList()}
    </>
  )
}

export default ProjectShares
