import React, { useRef, useEffect } from 'react'

import { dataIdFromObject } from '@sketch/graphql-cache'

import {
  useForTablet,
  DocumentHead,
  ErrorMessage,
  DocumentHeaderOld,
  handleFetchMore,
  useBanner,
  LoadingState,
} from '@sketch/components'

import { localStorageKeys } from '@sketch/constants'
import { useFlag, useUserProfile } from '@sketch/modules-common'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { getParsedItem, setStringifiedItem, useOnEvent } from '@sketch/utils'

import { useCanUseDesignSystemManager } from 'modules/designSystems/operations/useCanUseDesignSystemManager'
import { useSearch } from '../hooks/useSearch'
import { useSortingSettings } from '../hooks/useSortSettings'
import { useSearchFilters } from '../hooks/useSearchFilters'

import MfaBanner from 'components/MfaBanner'
import { DocumentItemDropdown } from 'modules/shares/components/DocumentItemDropdown'

import DocumentItemsLayout from './DocumentItemsLayout'
import ShareEmptyState from './ShareEmptyState'

import {
  useGetSharesQuery,
  useGetSharesLibrariesQuery,
  useGetWorkspaceUploadsRestrictedForQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

import { UpsellBanner } from 'modules/designSystems/components/UpsellBanner'
import { ScrollToTop } from 'components/ScrollToTopButton'

interface WorkspaceSharesListProps {
  workspaceId: string
  workspace: WorkspaceMinimalFragment
  filter?: 'LIBRARY' | 'NO_PROJECT' | 'SHARED_WITH_ME' | 'TEMPLATE' | null
  isMember?: boolean
  showArchived?: boolean
  sectionTitle?: string | null
}

const entriesPath = ['workspace', 'shares', 'entries']

const HEADER_COPY = {
  SHARED_WITH_ME: {
    title: 'Shared with Me',
    description: 'All documents that are directly shared with you.',
    learnMoreTooltipTitle: 'Learn more about Shared with Me',
    learnMoreTooltipURL: `${SKETCH_WEBSITE}/docs/sharing-and-collaborating/sharing-and-viewing-documents/shared-with-me/#how-to-access-shared-with-me`,
  },
  LIBRARY: {
    title: 'Libraries',
    description:
      "Libraries are documents with components that can be shared across other documents. To add a Library, select Use as Library in a document's settings.",
    learnMoreTooltipTitle: 'Learn more about Libraries',
    learnMoreTooltipURL: `${SKETCH_WEBSITE}/docs/libraries/`,
  },
  TEMPLATE: {
    title: 'Templates',
    description:
      'Templates are reusable Workspace documents with pre-populated content.',
    learnMoreTooltipTitle: 'Learn more about Templates',
    learnMoreTooltipURL: `${SKETCH_WEBSITE}/docs/symbols-and-styles/templates/`,
  },
  NO_PROJECT: {
    title: 'All Documents',
    description:
      'All the documents in your Workspace — in sync and available anywhere.',
    learnMoreTooltipTitle: 'Learn more',
    learnMoreTooltipURL: `${SKETCH_WEBSITE}/docs/sharing-and-collaborating/using-your-workspace/viewing-documents/`,
  },
}

const WorkspaceSharesList = ({
  workspaceId,
  filter = null,
  workspace,
  isMember,
  showArchived,
  sectionTitle,
}: WorkspaceSharesListProps) => {
  const isDesignSystemsOn = useFlag('design-systems')
  const isRefreshedUi = useFlag('ui-refresh-24')

  const { searchDebounced: search } = useSearch()
  const { sort } = useSortingSettings()

  const isTabletAndBigger = useForTablet()

  const workspaceHasDocuments = useRef<boolean>()
  const { showBanner } = useBanner()

  const userProfile = useUserProfile()
  const isVerified = userProfile?.data?.me?.isVerified
  const isMfaEnabled = userProfile?.data?.me?.mfaState === 'ENABLED'
  const isSsoWorkspace = workspace?.customer?.ssoEnabled
  const isArchivedEnabled = workspace.features.archiveEnabled

  const { canUseDesignSystemManager } = useCanUseDesignSystemManager({
    workspace,
  })

  const { filters } = useSearchFilters()
  if (filter) {
    filters.push(filter)
  }

  /*
   * We want to show Archived Documents when the user selects the proper filter
   * or when the user is searching for something (with or without the filter enabled)
   */
  const showArchivedDocuments = (isArchivedEnabled && showArchived) || search

  const variables = {
    identifier: workspaceId,
    after: null,
    search: {
      name: search,
      filters,
    },
    sortOrder: sort,
    archived: showArchivedDocuments ? ('ALL' as const) : undefined,
  }

  // check if the user has permissions to get the design systems - DIFFERENT from the business flag
  const canAccessDesignSystems =
    isMember && isDesignSystemsOn && filter === 'LIBRARY'

  const resultDocuments = useGetSharesQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    skip: canAccessDesignSystems,
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.identifier !== curr?.identifier,
  })

  const resultLibrary = useGetSharesLibrariesQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    skip: !canAccessDesignSystems,
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.identifier !== curr?.identifier,
  })

  // libraries need to get the design systems data
  const result = canAccessDesignSystems ? resultLibrary : resultDocuments
  const workspaceStatus = result.data?.workspace?.status

  const uploadsRestrictedForResult = useGetWorkspaceUploadsRestrictedForQuery({
    variables: { identifier: workspaceId },
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.identifier !== curr?.identifier,
  })

  useOnEvent('workspaceShareRefresh', ({ workspaceIdentifier }) => {
    if (workspaceIdentifier !== workspaceId) {
      // Not the workspace visible ignore then
      return
    }
    result.refetch()
  })

  // We are keeping the totalCount in a ref
  // to see if the Workspace has documents
  // to control Banner logic even if a search
  // returns a totalCount of 0
  useEffect(() => {
    if (!search) {
      const totalCount = result?.data?.workspace?.shares?.meta?.totalCount
      workspaceHasDocuments.current = !!totalCount
    }
  }, [result, search])

  const sharesCount = result.data?.workspace.shares.meta.totalCount ?? 0

  // Mfa Banner to encourage the user to enable it
  useEffect(() => {
    if (!isMfaEnabled && isVerified && sharesCount !== 0 && !isSsoWorkspace) {
      const now = new Date()
      const stopRenderAfter = getParsedItem(localStorageKeys.mfaBanner)

      // if the date wasn't set before, set it
      if (!stopRenderAfter) {
        const oneDayFromNow = new Date(Date.now() + 3600 * 1000 * 24)

        setStringifiedItem(localStorageKeys.mfaBanner, oneDayFromNow)
      }

      if (!(now > new Date(stopRenderAfter))) {
        const { dismissBanner } = showBanner({
          id: 'mfa-banner',
          type: 'custom',
          message: <MfaBanner />,
        })

        return () => {
          dismissBanner()
        }
      }
    }
  }, [isSsoWorkspace, isMfaEnabled, isVerified, sharesCount, showBanner])

  if (!result?.data && result?.error) {
    return <ErrorMessage.Generic />
  }

  const shares = result?.data?.workspace.shares.entries || []

  const after = result.data?.workspace.shares.meta.after || null
  const loadMoreHandler = handleFetchMore(result.fetchMore, entriesPath, {
    dataIdFromObject,
    after,
  })

  const loading = result.networkStatus < 3 || uploadsRestrictedForResult.loading
  const totalShareCount = result?.data?.workspace.shares.meta.totalCount || 0
  const headerCopy = filter ? HEADER_COPY[filter] : HEADER_COPY['NO_PROJECT']

  // if we are on libraries, we don't want to show an empty screen straight away
  if (!loading && shares.length === 0) {
    const uploadsRestrictedFor =
      uploadsRestrictedForResult.data?.workspace?.uploadsRestrictedFor ??
      // we should never need to default back to 'NONE', as we just checked
      // that uploadsRestrictedForResult is not in a loading state anymore
      // So there should be a valid data, but just in case, default it back
      // to 'NONE' either way.
      'NONE'
    return (
      <>
        {headerCopy && <DocumentHead title={headerCopy.title} />}

        {isTabletAndBigger && headerCopy && !isRefreshedUi && (
          <DocumentHeaderOld
            title={headerCopy.title}
            description={headerCopy.description}
            learnMoreTooltipTitle={headerCopy.learnMoreTooltipTitle}
            learnMoreTooltipURL={headerCopy.learnMoreTooltipURL}
          />
        )}

        <ShareEmptyState
          filter={filter}
          search={search}
          workspace={workspace}
          workspaceHasDocuments={workspaceHasDocuments.current}
          uploadsRestrictedFor={uploadsRestrictedFor}
        />
      </>
    )
  }

  // if library, get the upsell banner
  const showUpsell = !canUseDesignSystemManager && filter === 'LIBRARY'

  if (loading) {
    return <LoadingState />
  }

  return (
    <>
      {headerCopy && <DocumentHead title={headerCopy.title} />}
      {isTabletAndBigger && headerCopy && !isRefreshedUi && (
        <DocumentHeaderOld
          title={headerCopy.title}
          description={headerCopy.description}
          learnMoreTooltipTitle={headerCopy.learnMoreTooltipTitle}
          learnMoreTooltipURL={headerCopy.learnMoreTooltipURL}
        />
      )}

      {showUpsell && <UpsellBanner />}

      <DocumentItemsLayout
        shares={shares}
        search={search}
        renderDropdown={share => (
          <DocumentItemDropdown
            share={share}
            workspaceIdentifier={workspaceId}
            workspaceStatus={workspaceStatus}
            queryVariables={variables}
          />
        )}
        onLoadMore={loadMoreHandler}
        totalCount={totalShareCount}
        showProjectName
      />

      <ScrollToTop />
    </>
  )
}

export default WorkspaceSharesList
