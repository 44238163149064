import { jsx } from 'react/jsx-runtime';
import { matchPath, withRouter } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage/index.js';
import { IS_EMBEDDED } from '@sketch/constants';
import '../../routes/index.js';
import { versionedRouteKeys, getAllTemplatesForVersionedRoute, getRouteTemplate } from '../../routes/routes.js';

const isVersionedRouteKey = (routeKey) => {
    return versionedRouteKeys.includes(routeKey);
};
const isMatching = (pathname, routeKey, exact = true) => {
    const routePaths = isVersionedRouteKey(routeKey)
        ? getAllTemplatesForVersionedRoute(routeKey)
        : [getRouteTemplate(routeKey)];
    const match = matchPath(pathname, {
        path: routePaths,
        exact,
    });
    return !!match;
};
// TODO: Split LoadingPage and DynamicLoadingPage https://github.com/sketch-hq/Cloud/issues/16626
const RouteBasedLoadingPage = ({ location, }) => {
    // We want to maintain full control over what is rendered while embedded
    // therefore return null early here, rather than attempting to return
    // a dynamic loading state
    if (IS_EMBEDDED)
        return null;
    const match = (routeKey, exact = true) => isMatching(location.pathname, routeKey, exact);
    switch (true) {
        case match('ARTBOARD_DETAIL'):
        case match('ARTBOARD_DETAIL_UUID'):
        case match('ARTBOARD_REVISION'):
        case match('ANNOTATION_REDIRECT'):
        case match('SHARE_VIEW'):
        case match('SHARE_PAGE_CANVAS_VIEW'):
            return jsx(LoadingPage, { hideFooter: true });
        case match('SSO_LINK_ACCOUNT'):
        case match('SSO_TOS'):
            return jsx(LoadingPage.Sso, {});
        case match('WORKSPACE_SUBSCRIBE'):
        case match('WORKSPACE_CREATE'):
        case match('WORKSPACE_CREATE_DONE'):
        case match('WORKSPACE_INVITE'):
            return jsx(LoadingPage.WorkspaceSubscribe, {});
        case match('SIGN_UP'):
            return jsx(LoadingPage.IndexLayout, {});
        case match('SIGN_IN'):
        case match('FORGOT_PASSWORD'):
        case match('RESET_PASSWORD'):
            return jsx(LoadingPage.IndexLayout, { variant: "orange" });
        case match('UPDATES'):
        case match('PERSONAL_SETTINGS'):
        case match('PERSONAL_SETTINGS_ACCESS_TOKENS', false):
        case match('WORKSPACE', false):
            return jsx(LoadingPage.WorkspaceSidebar, {});
        default:
            return jsx(LoadingPage, {});
    }
};
var RouteBasedLoadingPage$1 = withRouter(RouteBasedLoadingPage);

export { RouteBasedLoadingPage$1 as default };
