import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import React from 'react';
import { useLocalStorage } from 'react-use';
import { localStorageKeys } from '@sketch/constants';
import { ArrowWrapper, StyledLinkButton, CloseIconContainer } from './NewFeatureHighlightPopover.styles.js';
import '../NewFeatureHighlightPopoverBody/index.js';
import '../Box/index.js';
import '../Popover/index.js';
import '../Button/index.js';
import '../NewFeatureHighlight/index.js';
import { ReactComponent } from '@sketch/icons/cross-16';
import { NewFeatureHighlight } from '../NewFeatureHighlight/NewFeatureHighlight.js';
import { Button } from '../Button/Button.js';
import { Popover } from '../Popover/Popover.js';
import { Box, Flex } from '../Box/BoxSystem.js';
import { ButtonUnstyled } from '../Button/ButtonUnstyled.js';
import { NewFeatureHighlightPopoverBody } from '../NewFeatureHighlightPopoverBody/NewFeatureHighlightPopoverBody.js';

const Arrow = React.forwardRef(function Arrow(props, ref) {
    return (jsx(ArrowWrapper, Object.assign({}, props, { ref: ref }, { children: jsx(NewFeatureHighlight, {}) })));
});
const NewFeatureHighlightPopover = ({ onConfirm, confirmCopy, closeButtonTestId = 'new-feature-hightlight-close', buttonLinkProps, titleText, bodyText, localStorageKey, show = true, children, showPrimaryCloseButton, placement, modifiers, }) => {
    const [introductionSeen, setIntroductionSeen] = useLocalStorage(localStorageKeys[localStorageKey], false);
    const closePopover = () => setIntroductionSeen(true);
    if (introductionSeen || !show) {
        return jsx(Fragment, { children: children });
    }
    const onConfirmClick = () => {
        closePopover();
        onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm();
    };
    const getButton = () => {
        if (!buttonLinkProps && !confirmCopy) {
            return undefined;
        }
        if (buttonLinkProps) {
            return (jsx(StyledLinkButton, Object.assign({ variant: "secondary", size: "24", type: "button" }, buttonLinkProps, { children: buttonLinkProps.children })));
        }
        else {
            return (jsx(Button, Object.assign({ variant: "primary", size: "24", onClick: onConfirmClick }, { children: confirmCopy })));
        }
    };
    return (jsx(Popover, Object.assign({ arrowComponent: Arrow, placement: placement, modifiers: modifiers, popup: jsxs(Box, Object.assign({ p: 4, width: 262 }, { children: [jsx(CloseIconContainer, { children: jsx(ButtonUnstyled, Object.assign({ onClick: closePopover, "data-testid": closeButtonTestId }, { children: jsx(ReactComponent, { width: "16px" }) })) }), jsx(NewFeatureHighlightPopoverBody, { titleText: titleText, bodyText: bodyText }), getButton() ? (jsxs(Flex, Object.assign({ mt: 4, justifyContent: "flex-end" }, { children: [getButton(), showPrimaryCloseButton && (jsx(Button, Object.assign({ variant: "primary", size: "24", onClick: onConfirmClick }, { children: "Close" })))] }))) : null] })), visible: !introductionSeen }, { children: children })));
};

export { NewFeatureHighlightPopover };
