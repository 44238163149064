import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { NotEmbedded, IsEmbedded, LoadingState } from '@sketch/components';
import styled from 'styled-components';
import '../DocumentSidebarLayoutOld/index.js';
import '../EmptyHeader/index.js';
import '../Footer/index.js';
import { DocumentSidebarLayoutOld } from '../DocumentSidebarLayoutOld/DocumentSidebarLayoutOld.js';
import { EmptyHeader } from '../EmptyHeader/EmptyHeader.js';
import { Footer } from '../Footer/Footer.js';

/**
 * TODO: reuse code between Layout and LoadingPage
 * https://github.com/sketch-hq/Cloud/issues/3543
 */
const Container = styled.div `
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;
const SidebarLoadingPage = ({ footer = jsx(Footer, {}), sidebarRight = () => null, message = undefined, }) => (jsx(Container, { children: jsx(DocumentSidebarLayoutOld, Object.assign({ header: jsxs(Fragment, { children: [jsx(NotEmbedded, { children: jsx(EmptyHeader, { hideLogo: true, isDocumentView: true }) }), jsx(IsEmbedded, { children: jsx(EmptyHeader, {}) })] }), footer: footer, sidebarRight: sidebarRight }, { children: jsx(LoadingState, { message: message }) })) }));

export { SidebarLoadingPage };
