import React, { FC, useCallback, useEffect } from 'react'
import {
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'

import { Document } from 'modules/shares/components/Document'
import DocumentHeader from 'modules/shares/components/DocumentHeader'
import {
  RouteParams,
  versionedRoutes,
  CWV_ROUTES,
  SketchHomeButton,
  getVersionRenderStatus,
  useFlag,
} from '@sketch/modules-common'
import { CwvRoutes } from 'modules/shares/ComponentsWebView'
import {
  Flex,
  useForTablet,
  NotEmbedded,
  IsEmbedded,
  Navbar,
  BannersDisplay,
} from '@sketch/components'

import DocumentSidebar from '../components/DocumentSidebar'
import ShareReadyGuard from '../components/ShareReadyGuard'

import { ResponsiveValues } from '@sketch/global-styles'
import {
  PageContent,
  BannerWrapper,
  NavbarBackButtonItem,
} from './DocumentView.styles'

import {
  useVersioning,
  isUpgradeToLatestNeeded,
  UpgradeToLatestVersion,
} from 'modules/versioning'
import { useHandleSettingsQueryParamIfNeeded } from './DocumentView.hooks'
import { useGetVersionQueryWithPolling } from '../operations'
import { useSearch } from '../hooks/useSearch'

// Context
import {
  SearchComponentsContext,
  InspectContextProvider,
  SelectedGroupContextProvider,
  CustomGridContextProvider,
  ComponentDescriptionContextProvider,
} from '../ComponentsWebView/context'
import { ComponentsStateProvider } from 'modules/shares/components/ComponentsStateContext'

import { getGroupType } from '../ComponentsWebView/utils'

import {
  DocumentMobileToolbar,
  isMobileToolboxItem,
} from '../components/DocumentMobileToolbar'
import BackButton from '../components/BackButton'
import HeaderNavigation from '../components/HeaderNavigation'
import { AnnotationQueryVariablesProvider } from 'modules/annotations/context'
import {
  DocumentSidebarLayoutExtraProps,
  SidebarLayoutChildrenProps,
} from '../components/DocumentSidebarLayout'
import { Panel } from '../components/Panel'
import { OnboardingPanelFloatingPanels } from '../PageCanvasView/OnboardingPanelFloatingPanels'

/**
 * CONSTANTS
 */
const COLUMNS_WITH_SIDEBAR: ResponsiveValues<number> = [1, 1, 1, 2, 3, 4, 5, 6]
const COLUMNS_WITHOUT_SIDEBAR: ResponsiveValues<number> = [
  2,
  2,
  2,
  3,
  4,
  5,
  6,
  7,
]

const PROTOTYPES_ROUTES = [
  versionedRoutes.SHARE_PROTOTYPES.LATEST.template(),
  versionedRoutes.SHARE_PROTOTYPES.VERSION.template(),
]

/**
 * TYPES
 */
interface ComponentsProvidersProps
  extends Pick<
    SidebarLayoutChildrenProps,
    'isSidebarRightOpen' | 'toggleSidebarRight'
  > {
  search: string
  userCanInspect?: boolean
}

/**
 * COMPONENTS
 */
const ComponentsProviders: FC<ComponentsProvidersProps> = ({
  children,
  search,
  toggleSidebarRight,
  isSidebarRightOpen,
  userCanInspect,
}) => {
  // used to reset the scroll to 0 everytime a new search is inputted
  // so we don't get search results loading with the scroll all way down
  useEffect(() => {
    const scrollingContainer = document.querySelector(
      '[data-content-scrollable]'
    )
    if (scrollingContainer) {
      scrollingContainer.scrollTop = 0
    }
  }, [search])

  const onInspectorItemSelect = useCallback(() => {
    if (!isSidebarRightOpen && userCanInspect) {
      toggleSidebarRight()
    }
  }, [isSidebarRightOpen, toggleSidebarRight, userCanInspect])

  return (
    <SearchComponentsContext.Provider value={{ search }}>
      <SelectedGroupContextProvider>
        <InspectContextProvider onItemSelect={onInspectorItemSelect}>
          <CustomGridContextProvider>
            <ComponentDescriptionContextProvider>
              <ComponentsStateProvider>{children}</ComponentsStateProvider>
            </ComponentDescriptionContextProvider>
          </CustomGridContextProvider>
        </InspectContextProvider>
      </SelectedGroupContextProvider>
    </SearchComponentsContext.Provider>
  )
}

/**
 * Used for:
 * - Legacy Page view (SHARE_PAGE_VIEW)
 * - Prototype view (SHARE_PROTOTYPES)
 * - CWV view (symbol, text styles, layer styles or color variables)
 */
const DocumentView = (props: DocumentSidebarLayoutExtraProps) => {
  const {
    toggleSidebarRight,
    isSidebarRightOpen,
    setSidebarLeftOpen,
    HeaderPortal,
    SidebarRightPortal,
    useOverrideLayoutProps,
  } = props

  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  const location = useLocation<{
    emptySearch: boolean
  }>()
  const params = useParams<
    RouteParams<'SHARE_VIEW'> | RouteParams<'SHARE_PAGE_VIEW'>
  >()
  const { path } = useRouteMatch()

  // Search query param, shared for both artboards and components
  const { search, setSearch, searchDebounced } = useSearch()

  // Reset search when moving between pages, symbols, text styles, layer styles
  // and color variables. But we want to keep it when coming back from artboards
  useEffect(() => {
    if (location.state?.emptySearch) {
      setSearch('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  const versioning = useVersioning()
  const {
    versionShortId,
    share,
    latestVersionId,
    isViewingLatestVersion,
    hasPendingPatches,
  } = versioning
  const currentVersion = !versioning.loading ? versioning.currentVersion : null
  const { data, error } = useGetVersionQueryWithPolling({
    variables: {
      shareIdentifier: share.identifier,
      versionShortId,
    },
  })
  const documentName = share.name

  // Override default layout values
  useOverrideLayoutProps({
    title: documentName,
    darkBackground: true,
    hideBackButton: true,
    sidebarOnTopOfHeader: false,
  })

  useEffect(() => {
    if (versioning.artboardThumbnail !== '') {
      // clear the artboard thumbnail
      versioning.setArtboardThumbnail('')
    }
  }, [versioning])

  // Handle potential deeplink to settings modal
  useHandleSettingsQueryParamIfNeeded(share, {
    current: { shortId: versionShortId },
    latest: { shortId: latestVersionId },
  })

  const isTabletAndBigger = useForTablet()

  if (isUpgradeToLatestNeeded(error)) {
    return <UpgradeToLatestVersion error={error} />
  }

  const parsedRenderStatus = getVersionRenderStatus(data?.share?.version)

  const CWVRoute = getGroupType(path)
  const isPrototypePage = PROTOTYPES_ROUTES.includes(path)

  const permanentPageIdURL = 'pageUUID' in params ? params.pageUUID : undefined
  const firstDocumentPageIdentifier =
    currentVersion?.document?.pages?.entries[0]?.uuid

  const permanentPageId =
    permanentPageIdURL || firstDocumentPageIdentifier || ''

  const userIsEditor = share.userCanEdit
  const userCanInspect = share.userCanInspect

  const isCurrentVersionStarred = currentVersion?.kind === 'PUBLISHED'

  const MOBILE_TOOLBAR_ITEMS = ['search', 'doc-info', 'versions'].filter(
    isMobileToolboxItem
  )

  return (
    <AnnotationQueryVariablesProvider
      shareIdentifier={share.identifier}
      subjects={[{ type: 'PAGE', permanentId: permanentPageId }]}
    >
      <ComponentsProviders
        search={search}
        isSidebarRightOpen={isSidebarRightOpen}
        toggleSidebarRight={toggleSidebarRight}
        userCanInspect={userCanInspect}
      >
        <HeaderPortal>
          <NotEmbedded>
            <DocumentHeader
              share={share}
              setSidebarLeftOpen={setSidebarLeftOpen}
              toggleSidebarRight={toggleSidebarRight}
              isSidebarRightOpen={isSidebarRightOpen}
              isViewingLatestVersion={isViewingLatestVersion}
              isViewingStarredVersion={isCurrentVersionStarred}
              hasPendingPatches={hasPendingPatches}
              userCanOpenInApp={!!currentVersion?.document?.userCanOpenInApp}
              currentVersion={currentVersion}
            />

            <Panel
              share={share}
              version={currentVersion || undefined}
              userCanSeeComments
              SidebarRightPortal={SidebarRightPortal}
            />

            <OnboardingPanelFloatingPanels />
          </NotEmbedded>
          <IsEmbedded>
            <Navbar>
              <Navbar.Section>
                <SketchHomeButton />

                {isTabletAndBigger && (
                  <NavbarBackButtonItem>
                    <BackButton />
                  </NavbarBackButtonItem>
                )}

                <HeaderNavigation share={share} />
              </Navbar.Section>
            </Navbar>
          </IsEmbedded>
        </HeaderPortal>

        <SidebarRightPortal>
          <NotEmbedded>
            <DocumentSidebar
              share={share}
              userCanSeeComments={!isPrototypePage}
              userCanInspect={share.userCanInspect}
              toggleSidebar={toggleSidebarRight}
              CWVRoute={CWVRoute}
            />
          </NotEmbedded>
        </SidebarRightPortal>

        <PageContent $isRefreshedUi={isRefreshedUi}>
          <ShareReadyGuard renderStatus={parsedRenderStatus}>
            <>
              <BannerWrapper>
                <BannersDisplay />
              </BannerWrapper>
              <Flex flex="1">
                <Switch>
                  <CwvRoutes
                    exact
                    path={CWV_ROUTES}
                    userIsEditor={userIsEditor}
                  />
                  <Route>
                    <Document
                      columns={
                        isSidebarRightOpen
                          ? COLUMNS_WITH_SIDEBAR
                          : COLUMNS_WITHOUT_SIDEBAR
                      }
                      search={searchDebounced}
                      showPrototypeArtboards={!searchDebounced}
                    />
                  </Route>
                </Switch>
              </Flex>
              {!isTabletAndBigger && (
                <DocumentMobileToolbar items={MOBILE_TOOLBAR_ITEMS} />
              )}
            </>
          </ShareReadyGuard>
        </PageContent>
      </ComponentsProviders>
    </AnnotationQueryVariablesProvider>
  )
}

export default DocumentView
