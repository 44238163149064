import React from 'react'

import { DiscoverResources } from '@sketch/discover'

import {
  EditorialWrapper,
  Wrapper,
  EmptyState,
  Footer,
} from './DocumentListEmptyState.styles'
import { useBreakpoint } from '@sketch/components'
import { useFlag } from '@sketch/modules-common'

type EditorialContent = React.ComponentProps<typeof DiscoverResources>['type']

interface DocumentListEmptyStateProps {
  title: string
  description: string
  editorialContent?: EditorialContent
  actions?: React.ReactNode
}

const DocumentListEmptyState = (props: DocumentListEmptyStateProps) => {
  const { title, description, editorialContent, actions } = props
  const isTabletOrBigger = useBreakpoint('sm')

  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  return (
    <Wrapper>
      <EmptyState title={title} description={description} actions={actions} />

      {editorialContent && (
        <EditorialWrapper $isRefreshedUi={isRefreshedUi}>
          <DiscoverResources type={editorialContent} />
        </EditorialWrapper>
      )}

      {/**
       * We don't show the footer on this empty state mobile breakpoint
       * https://www.sketch.com/s/2134e167-c364-4230-a3b2-05ebb17f0836/a/lR1REdz
       */}
      {isTabletOrBigger && <Footer />}
    </Wrapper>
  )
}

export default DocumentListEmptyState
