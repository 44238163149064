import { useCallback } from 'react'
import {
  useExportNode,
  usePRFile,
  ExportResult,
} from '@sketch-hq/sketch-web-renderer'
import { ExportSettingsItem } from './types'
import { useToast } from '@sketch/toasts'
import { useGetIdentifiers } from 'modules/shares/hooks/useGetIdentifiers'
import { ErrorHandler } from '@sketch/tracing'

type ExportResultSuccess = ExportSettingsItem & { data: Blob }
type ExportResultFailed = ExportSettingsItem & { data: null }
type ExportSettingsItemResult = ExportResultSuccess | ExportResultFailed

/**
 * Returns a function that we can call to export and download a layer.
 */
export function useExportAndDownloadLayer() {
  const exportNode = useExportNode()
  const { showToast } = useToast()
  const { shareIdentifier, versionIdentifier } = useGetIdentifiers()
  const findLayerUuid = useFindLayerUuid()

  return useCallback(
    async (
      nodeIdentifier: bigint,
      elementName: string,
      exportList: ExportSettingsItem[]
    ) => {
      const { exports, failed } = await runMultiExports(
        nodeIdentifier,
        exportList,
        exportNode
      )
      const layerUUID = findLayerUuid(nodeIdentifier)

      if (exports.length === 0) {
        showToast(
          'Asset export failed. Please try again or contact our support team.',
          'negative'
        )
        ErrorHandler.shouldNeverHappen(
          `Export failed for layer "${layerUUID}" (share: ${shareIdentifier}, version: ${versionIdentifier})`
        )
        return
      }

      if (failed.length > 0) {
        showToast(
          'Some assets could not be exported. Please try again or contact our support team.',
          'info'
        )
        ErrorHandler.shouldNeverHappen(
          `Export partially failed for layer "${layerUUID}" (share: ${shareIdentifier}, version: ${versionIdentifier})`
        )
      }

      if (exports.length === 1) {
        const exportResult = exports[0]
        const fileName = buildFileName(elementName, exportList[0])
        if (!fileName.includes('/')) {
          // If there is only one file and we don't need to create any folder,
          // download it as a single file. Otherwise, download as a zip.
          downloadFileWithName(URL.createObjectURL(exportResult.data), fileName)
          return
        }
      }

      downloadResultsAsZip(exports, elementName)
    },
    [exportNode, findLayerUuid, shareIdentifier, showToast, versionIdentifier]
  )
}

type MultiExportsResult = {
  exports: ExportResultSuccess[]
  failed: ExportResultFailed[]
}

/**
 * Run potentially multiple exports for the same layers for the different export settings.
 * All the exports will be triggered concurrently but will resolve in one final promise containing
 * the successful exports and the failed exports.
 */
async function runMultiExports(
  nodeIdentifier: bigint,
  exportList: ExportSettingsItem[],
  exportNode: ReturnType<typeof useExportNode>
): Promise<MultiExportsResult> {
  // All promises will resolve but with or without data.
  const exportsPromiseList = exportList.map(
    async (
      exportItem: ExportSettingsItem
    ): Promise<ExportSettingsItemResult> => {
      let exportResult: ExportResult | null = null
      try {
        exportResult = await exportNode(nodeIdentifier, exportItem)
      } catch (err) {
        // Recover error, do nothing, data will be null
        // This enables to continue with the export even if it is partial.
      }

      return {
        ...exportItem,
        data: exportResult?.blob ?? null,
      }
    }
  )

  const results = await Promise.all(exportsPromiseList)

  // Split the results into successful exports and failed exports.
  return results.reduce(
    (acc: MultiExportsResult, exportResult) => {
      if (exportResult.data) {
        acc.exports.push(exportResult)
      } else {
        acc.failed.push(exportResult)
      }
      return acc
    },
    { exports: [], failed: [] }
  )
}

/**
 * Create the name and the path of the file to be exported.
 * Path can contain slashes to represent a folder structure.
 */
export function buildFileName(
  elementName: string,
  settings: ExportSettingsItem
): string {
  const prefixOrSuffix = settings.prefixSuffix.content

  let fileName = elementName
  if (settings.prefixSuffix.type === 'suffix') {
    fileName = `${elementName}${prefixOrSuffix}`
  } else if (settings.prefixSuffix.type === 'primaryPrefix') {
    fileName = `${prefixOrSuffix}${elementName}`
  } else if (settings.prefixSuffix.type === 'secondaryPrefix') {
    const pathSegments = elementName.split('/')

    const baseFileName = pathSegments.pop()
    const directoryPath = pathSegments.join('/')
    fileName = `${directoryPath}/${prefixOrSuffix}${baseFileName}`
  }

  // Use trim slashes in case the user has used `/..` in the prefix or
  // `.../` in the suffix. The zip library does not play well with those leading/trailing slashes.
  return `${trimSlashes(fileName)}.${settings.format}`
}

/**
 * Programmatically trigger download of a file.
 */
function downloadFileWithName(url: string, name: string) {
  const a = document.createElement('a')
  a.href = url
  a.download = name
  a.click()
}

async function downloadResultsAsZip(
  exportResults: ExportResultSuccess[],
  elementName: string
) {
  const JSZip = (await import('jszip')).default
  const zip = new JSZip()

  exportResults.forEach(result => {
    const fileName = buildFileName(elementName, result)

    // The jszip API automatically handles folder through slashes in the path name.
    // i.e. filename 'folder1/folder2/file.png' => will result in the structure:
    // folder1 => folder2 => file.png.
    zip.file(fileName, result.data)
  })

  const zipData = await zip.generateAsync({ type: 'blob' })
  const url = URL.createObjectURL(zipData)
  downloadFileWithName(url, elementName)
}

/**
 * Remove any leading/trailing slashes from a string
 */
export function trimSlashes(filePath: string): string {
  return filePath.replace(/^\/+|\/+$/g, '')
}

function useFindLayerUuid() {
  const prFile = usePRFile()

  return useCallback(
    (nodeIdentifier: bigint) => {
      const node = prFile?.findByIdentifier(nodeIdentifier)

      return node?.getLayerUUID() ?? ''
    },
    [prFile]
  )
}
