import React, { FC, useEffect, useState, useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { ArtboardDetailInfoFragment, VersionFragment } from '@sketch/gql-types'
import { IS_EMBEDDED } from '@sketch/constants'
import { shortcuts } from '@sketch/utils'
import { ShareWithoutVersion, useVersioning } from 'modules/versioning'
import { versionedRoutes } from '@sketch/modules-common'
import {
  useForTablet,
  MenuIconButton,
  TooltipShortcut,
} from '@sketch/components'

import { useHasNewNotifications } from 'modules/annotations/hooks'

import HeaderNavigation from 'modules/shares/components/HeaderNavigation'
import VersionLinkButton from 'modules/shares/components/VersionLinkButton'
import PlayPrototypeLink from 'modules/shares/components/PlayPrototypeLink'
import DerivedHeaderActions from 'modules/shares/components/DerivedHeaderActions'

import { NavbarItem } from './ArtboardDetailHeader.styles'
import { ArtboardDetailSettingsDropdown } from '../ArtboardDetailSettingsDropdown'
import { DocumentSidebarLayoutExtraProps } from 'modules/shares/components/DocumentSidebarLayout'

import { ReactComponent as ChatBubbles } from '@sketch/icons/chat-bubbles-24'
import { ReactComponent as CodeBlock } from '@sketch/icons/code-block-24'
import { ReactComponent as ClockReWind } from '@sketch/icons/clock-rewind-24'

import {
  useShareSidebarTab,
  getActivePanels,
} from 'modules/shares/components/ShareSidebarTabContext'
import { VerticalDivider } from 'modules/shares/components/NavbarItem'

type DetailHeaderProps = {
  share: ShareWithoutVersion
  artboard?: ArtboardDetailInfoFragment
  artboardLatestVersion: string
  isViewingLatestVersion: boolean
  isPrototypePlayEnabled: boolean
  userCanOpenInApp: boolean
  currentVersion: VersionFragment | null
} & Pick<
  DocumentSidebarLayoutExtraProps,
  'setSidebarLeftOpen' | 'toggleSidebarRight' | 'isSidebarRightOpen'
>

export const ArtboardDetailHeader: FC<DetailHeaderProps> = ({
  share,
  artboard,
  toggleSidebarRight,
  isSidebarRightOpen,
  isPrototypePlayEnabled,
  isViewingLatestVersion,
  userCanOpenInApp,
  currentVersion,
}) => {
  const { identifier, userCanInspect, versionTypeAvailableToUser } = share
  const { path } = useRouteMatch()
  const isTabletAndBigger = useForTablet()
  const { latestVersionId } = useVersioning()

  const activePanels = useMemo(
    () =>
      getActivePanels({
        path,
        canInspect: userCanInspect,
        // Even when the comments are not allowed, the comments panel should be available
        canComment: true,
      }),
    [path, userCanInspect]
  )

  /**
   * Save the tabs the user has access and
   * update if "shouldShowCommentTab" and "shouldShowInspector" updates
   */
  const [segments, setSegments] = useState(activePanels)

  useEffect(() => {
    setSegments(activePanels)
  }, [activePanels])

  const { activeSegment, toggleSegment } = useShareSidebarTab(segments, {
    isSidebarRightOpen,
    toggleSidebarRight,
  })

  const hasNewNotifications = useHasNewNotifications()

  const showLatestVersionButton =
    isTabletAndBigger &&
    !isViewingLatestVersion &&
    !IS_EMBEDDED &&
    // never show if user can only access latest version (it will update automatically)
    versionTypeAvailableToUser !== 'LATEST'

  const latestVersionPathname = versionedRoutes.ARTBOARD_DETAIL.VERSION.create({
    permanentArtboardShortId: artboard?.permanentArtboardShortId!,
    shareID: identifier,
    versionShortId: latestVersionId,
  })

  return (
    <>
      <HeaderNavigation share={share} artboard={artboard} />

      <DerivedHeaderActions
        share={share}
        currentVersion={currentVersion}
        userCanOpenInApp={userCanOpenInApp}
        isViewingLatestVersion={isViewingLatestVersion}
      />

      {artboard && showLatestVersionButton && (
        <NavbarItem>
          <VersionLinkButton to={latestVersionPathname}>
            See Latest Version
          </VersionLinkButton>
        </NavbarItem>
      )}

      <NavbarItem>
        {artboard && isTabletAndBigger && artboard.isFlowHome && (
          <PlayPrototypeLink
            isPrototypePlayEnabled={isPrototypePlayEnabled}
            artboardUUID={artboard.uuid}
            shareIdentifier={identifier}
            representation="navbar"
          />
        )}
      </NavbarItem>

      {artboard && (
        <NavbarItem>
          <ArtboardDetailSettingsDropdown
            shareIdentifier={identifier}
            permanentArtboardId={artboard.uuid}
            permanentArtboardShortId={artboard.shortId}
            subscriptionStatus={artboard.subscriptionStatus}
          />
        </NavbarItem>
      )}

      {isTabletAndBigger && (
        <>
          <VerticalDivider />
          <NavbarItem data-testid="panel-triggers-header">
            <MenuIconButton
              description={
                <>
                  Versions{' '}
                  <TooltipShortcut>
                    Press {shortcuts.version.toUpperCase()}
                  </TooltipShortcut>
                </>
              }
              aria-current={activeSegment === 'Version'}
              onClick={() => toggleSegment('Version')}
            >
              <ClockReWind role="img" />
              <span className="sr-only">Versions</span>
            </MenuIconButton>

            {userCanInspect && (
              <MenuIconButton
                description={
                  <>
                    Inspect{' '}
                    <TooltipShortcut>
                      Press {shortcuts.inspect.toUpperCase()}
                    </TooltipShortcut>
                  </>
                }
                aria-current={activeSegment === 'Inspect'}
                onClick={() => toggleSegment('Inspect')}
              >
                <CodeBlock role="img" />
                <span className="sr-only">Inspect</span>
              </MenuIconButton>
            )}

            <MenuIconButton
              description={
                <>
                  Comments{' '}
                  <TooltipShortcut>
                    Press {shortcuts.comment.toUpperCase()}
                  </TooltipShortcut>
                </>
              }
              data-testid="comment-tab"
              aria-current={activeSegment === 'Comment'}
              onClick={() => toggleSegment('Comment')}
              $isHighlighted={hasNewNotifications}
            >
              <ChatBubbles role="img" />
              <span className="sr-only">Comments</span>
            </MenuIconButton>
          </NavbarItem>
        </>
      )}
    </>
  )
}
